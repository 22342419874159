@mixin bg-responsive($dekstopName, $mobileName, $breakpoint) {
  background-image: url('../img/#{$mobileName}@1x.png');
  background-repeat: no-repeat;
  background-position: center;

  @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
    background-image: url('../img/#{$mobileName}@2x.png');
  }

  @media (min-width: $breakpoint) {
    background-image: url('../img/#{$dekstopName}@1x.png');

    @media (min-resolution: 144dpi), (min-resolution: 1.5dppx) {
      background-image: url('../img/#{$dekstopName}@2x.png');
    }
  }
}


// Example:
// .selector
//   @include fluid('font-size', (500px: 24px, 1440px: 52px))

@mixin fluid($property, $map) {
  // Get the number of provided breakpoints
  $length: length(map-keys($map));

  // Error if the number of breakpoints is < 2
  @if $length < 2 {
    @error 'ff() $map requires at least values';
  }

  // Sort the map by viewport width (key)
  $map: map-sort($map);
  $keys: map-keys($map);

  // Minimum size
  #{$property}: map-get($map, nth($keys, 1));

  // Interpolated size through breakpoints
  @for $i from 1 through $length - 1 {
    @media (min-width: nth($keys, $i)) {
      #{$property}: linear-interpolation(
        (
          nth($keys, $i): map-get($map, nth($keys, $i)),
          nth($keys, $i + 1): map-get($map, nth($keys, $i + 1)),
        )
      );
    }
  }

  // Maximum size
  @media (min-width: nth($keys, $length)) {
    #{$property}: map-get($map, nth($keys, $length));
  }
}

@function linear-interpolation($map) {
  $keys: map-keys($map);

  @if length($keys) != 2 {
    @error "linear-interpolation() $map must be exactly 2 values";
  }

  // The slope
  $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1))) /
    (nth($keys, 2) - nth($keys, 1));

  // The y-intercept
  $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

  // Determine if the sign should be positive or negative
  $sign: '+';

  @if $b < 0 {
    $sign: '-';
    $b: abs($b);
  }

  @return calc(#{$m * 100}vw #{$sign} #{$b});
}

@function list-sort($list) {
  $sortedlist: ();

  @while length($list) > 0 {
    $value: nth($list, 1);

    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }

    $sortedlist: append($sortedlist, $value, 'space');
    $list: list-remove($list, index($list, $value));
  }

  @return $sortedlist;
}

@function map-sort($map) {
  $keys: list-sort(map-keys($map));
  $sortedMap: ();

  @each $key in $keys {
    $sortedMap: map-merge(
      $sortedMap,
      (
        $key: map-get($map, $key),
      )
    );
  }

  @return $sortedMap;
}

@function list-remove($list, $index) {
  $newList: ();

  @for $i from 1 through length($list) {
    @if $i != $index {
      $newList: append($newList, nth($list, $i), 'space');
    }
  }

  @return $newList;
}
