.input {
  position: relative;
  display: flex;
  align-items: center;

  textarea {
    min-height: 16rem;
    resize: none;
  }

  @include respond-min(tablet) {
    textarea {
      min-height: 8rem;
    }
  }
}

.input__el {
  flex-grow: 1;
  padding: 1.25rem 2.5rem;
  background-color: transparent;
  border: 1px solid map-get($colors, grey-5);

  &::placeholder {
    color: inherit;
    user-select: none;
  }

  &:hover,
  &:focus {
    border-color: map-get($colors, blue-22);
  }

  .input--file & {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  .input.invalid & {
    color: map-get($colors, red-2);
    border-color: map-get($colors, red-2);
  }

  @include respond-min(tablet) {
    padding: 0.625rem 1.25rem;
  }
}

.input__label {
  .input--file & {
    opacity: 0.5;
  }

  input[type='checkbox'] + & {
    --border-hover: #bbc1e1;
    position: relative;
    min-height: 2rem;
    padding-left: 4rem;
    cursor: pointer;

    svg {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: map-get($colors, blue-10);
      stroke-dasharray: var(--dasharray, 86.12);
      stroke-dashoffset: var(--dashoffset, 86.12);
      transform: translateZ(0);
      transition-duration: 0.6s;
      box-shadow: inset 0 0 0 1px map-get($colors, grey-5);
      pointer-events: none;
    }

    &:hover {
      svg {
        box-shadow: inset 0 0 0 1px map-get($colors, blue-22);
      }
    }
  }

  input[type='checkbox']:focus + & {
    svg {
      box-shadow: inset 0 0 0 1px map-get($colors, blue-22);
    }
  }

  input[type='checkbox']:checked + & {
    svg {
      --dasharray: 16.1 86.12;
      --dashoffset: 102.22;
    }
  }

  @include respond-min(tablet) {
    input[type='checkbox'] + & {
      min-height: 1rem;
      padding-left: 1rem;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.input__file-text {
  order: -1;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 1.25rem 2.5rem;
  text-align: center;
  border: 1px solid map-get($colors, grey-5);

  .input__el:hover ~ &,
  .input__el:focus ~ & {
    border-color: map-get($colors, blue-22);
  }

  p {
    &:nth-of-type(1) {
      display: block;

      .input.file-loaded & {
        display: none;
      }
    }

    &:nth-of-type(2) {
      display: none;

      .input.file-loaded & {
        display: block;
      }
    }
  }

  @include respond-min(tablet) {
    padding: 0.625rem 1.25rem;
  }
}

.input__icon {
  .input--file & {
    flex-shrink: 0;
    width: 1.875rem;
    height: 2.375rem;
    margin-right: 1.25rem;
  }

  @include respond-min(tablet) {
    .input--file & {
      width: 0.9375rem;
      height: 1.1875rem;
      margin-right: 0.625rem;
    }
  }
}

.input__error {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;

  .input.invalid & {
    opacity: 1;
    pointer-events: all;
  }

  @include respond-min(tablet) {
    top: calc(100% + 0.125rem);
  }
}
