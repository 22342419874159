.media-obj {
  display: flex;
  width: 100%;
  height: 100%;
  line-height: 0;

  picture {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
