.project-complete-page {
  max-width: 730px;
  margin: 0 auto;
}

.project-complete-page ul:not([class]) {
  list-style: none;
  padding: 0;
  margin: 0;

  color: $main-text-color;

  li {
    position: relative;
    padding-left: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 0.5em;
      left: 0;

      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $main-text-color;
    }
  }
}

.project-complete-page .t-h2 {
  color: $main-text-color;
  margin-bottom: 1.5rem;
}

.project-complete-page__back-wrap {
  display: block;
  margin-bottom: 2rem;
}

.project-complete-page__back {
  width: 2rem;
  height: 2rem;
}

.project-component-page__preview-text {
  font-size: 21px;

  opacity: 0.7;
  color: $main-text-color;

  @media (max-width: 768px) {
    font-size: 19px;
  }
}

.project-component-page__photos {
  position: relative;

  margin-top: 3rem;
}

.project-components-page__detail-text {
  margin-top: 56px;

  color: $main-text-color;
}

.project-components-page__detail-text,
.project-components-page__detail-text p {
  font-size: 16px;
}

.project-components-page__detail-text p {
  margin-bottom: 2rem;
}

.project-components-page__documents {
  display: flex;
  flex-wrap: wrap;

  margin-top: 2.5rem;
  padding: 2.25rem 0;

  border-top: 1px solid rgba(98, 111, 159, 0.1);
  border-bottom: 1px solid rgba(98, 111, 159, 0.1);
}

.project-components-page__reviews {
  padding: 4rem 0;

  border-top: 1px solid rgba(98, 111, 159, 0.1);
  border-bottom: 1px solid rgba(98, 111, 159, 0.1);
  margin-top: 4rem;
}

.project-components-page__documents + .project-components-page__reviews {
  margin-top: 0;
  border-top: 0;
}

.project-components-page__products {
  margin-top: 4rem;
}


.project-components-page__products-list {
  display: flex;
  flex-wrap: wrap;
}

.project-components-page__product-slide {
  width: calc((100% - 3.5rem)/3);
  margin-right: 1rem;
}

.project-component-page__photos-container {
  width: calc(100% - 4rem);
}

.project-component-page__info-slide {
  width: calc(100% / 3);
}

.project-component-page__info-thumb {
  padding: 1rem;
  height: 9.75rem;

  cursor: pointer;
}

.project-component-page__photos .swiper-button-next,
.project-component-page__photos .swiper-button-prev {
  width: 1rem;
}

// Form
.project-form {
  background: $main-text-color;
}

.project-form__container {
  max-width: 730px;
  margin: 0 auto;
  padding: 64px 0 88px;

  @media (max-width: 1023px) {
    max-width: unset;
    padding: 56px 2rem 80px;
  }
}

.project-form__inner {
  width: 100%;
}

.project-form__row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  .input {
    flex: 1;

    @media (max-width: 768px) {
      width: 100%;
    }

    &:not(:first-child) {
      @media (min-width: 1024px) {
        margin-left: 20px;
      }
    }
  }

  .btn {
    width: 182px;
  }

  .input__el {
    border: 1px solid rgba(#fff, 0.4);
  }
}

.project-form__checkbox {
  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }

  label {
    margin-left: 12px;
    font-size: 12px;
    line-height: 15px;
  }

  label,
  a {
    text-decoration: none;
    color: rgba(#fff, 0.5);
  }
}

.project-img-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 40px;
  height: 40px;

  padding: 0;
  font-size: 0;
  color: white;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 32px;

  &--prev {
    left: 40px;
    background-image: url(../assets/img/svg/chevron-slider-left.svg);
  }

  &--next {
    right: 40px;
    background-image: url(../assets/img/svg/chevron-slider-right.svg);
  }


}



// Reviews slider
.project-reviews__head {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;
}

.project-reviews__nav {
  display: flex;
  align-items: center;
}

.project-reviews__btn {
  background: 0;
  border: 0;
  font-size: 0;
  padding: 0;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  img {
    object-fit: cover;
  }
}

.project-reviews__btn--faded {
  opacity: 0.3;
}

.project-reviews__btn--prev .media-obj {
  transform: scale(-1);
}

.project-reviews__dots {
  margin: 0 16px;
  display: flex;
  align-items: center;

  > li:not(:first-child) {
    margin-left: 8px;
  }

  > li {
    display: flex;
  }
}

.project-reviews__dot {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 0;

  min-width: 4px;
  width: 4px;
  height: 4px;

  border-radius: 50%;
  background: #C1CFED;
  transition: 0.3s ease;

  &--active {
    background: #7B9DDF;
  }
}

