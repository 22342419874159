

.btn {
  @include text('t-sm');
  @include proxima('sb');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  padding: 1.875rem;
  text-align: center;
  border: 1.5px solid map-get($colors, blue-10);
  border-radius: 6rem;
  transition: $trs;

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  &--outline {
    overflow: hidden;
    position: relative;
    background-color: transparent;
    mask-image: radial-gradient(white, black);

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1.875rem;
      border: 1.5px solid map-get($colors, blue-10);
      border-radius: 6rem;
      background-color: map-get($colors, blue-10);
      transition: $trs;
      transition-property: transform;
      transform: translateX(100%);
    }

    &:hover,
    &:focus {
      opacity: 1;
      color: map-get($colors, white);
      background-color: map-get($colors, blue-10);

      &::before {
        transform: translateX(0);
      }
    }
  }
  &--inline {
    border: none;
    padding-bottom: 0!important;
  }
  @include respond-min(tablet) {
    padding: 0.9375rem;
    border-radius: 3rem;

    &--outline {
      &::before {
        padding: 0.9375rem;
        border-radius: 3rem;
      }
    }
  }
}

.btn__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}


.btn-submit{
  &:after{
    content: attr(data-text);
  }
}

.btn-submit.active{
  background: green;
  border-color: green;
  color: white;
  pointer-events: none;
  &:before{
    border-color: green;
    background: green;
  }
  &:after{
    content: attr(data-success);
  }
}

.btn--sale {
  font-size: 16px;
  min-height: 34px;
  height: 34px;
  padding: 0 15px;
  margin-left: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition-duration: .6s;
  background-color: map-get($colors, red-3);
}

.btn--promo {
  font-size: 16px;
  min-height: 36px;
  height: 36px;
  padding: 0 15px;
  vertical-align: middle;
  margin-left: 10px;
  border-radius: 30px;
  line-height: 17px;
  background: gradi;
  background: #005aa7;
  background: -webkit-linear-gradient(90deg, #005aa7, #fffde4);
  background: #faebd7;
  color: #fff;
  border: 0;
  background: #667db6;
  background: -webkit-linear-gradient(90deg,#667db6,#0082c8,#0082c8,#667db6);
  background: linear-gradient(90deg,#667db6,#0082c8,#0082c8,#667db6);
}
