.page--stop-scroll .page {
    overflow: hidden;
}

.modal.product-landpage__modal {
    opacity: 0;
    pointer-events: none;
    transform: translate(0);
    display: block !important;
    background-color: transparent;
    transition: 300ms ease;

    .input__el.error {
        border-color: map-get($colors, red-2);
    }

    .success-message {
        display: none;
    }

    .success-message {
        text-align: center;
        color: map-get($colors, green-1);
    }

    .success-message.is-active {
        display: block;
    }

    input, textarea {
        margin-bottom: 0;
    }
}

.product-landpage__modal.is-active {
    opacity: 1;
    pointer-events: all;
}

.modal__tab {
    display: none;
}

.modal__tab.open {
    display: block;
}