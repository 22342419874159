.trs {
  transition: $trs;

  .resized & {
    transition: none !important;
  }
}
@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(.85);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  33% {
    width: 0.4em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }

  100% {
    width: 0.4em;
    height: 1em;
    border-color: #212121;
    transform: translate3d(0, -1em, 0) rotate(45deg);
  }
}
