.slider-arrows {
  display: flex;
  align-items: center;
  margin-left: auto;

  @include respond-min(tablet) {
    flex-grow: 1;
    margin-left: 0;
  }
}

.slider-arrows__button {
  position: relative;
  width: 5rem;
  height: 5rem;
  padding: 0;
  font-size: 0;
  border: 1.5px solid currentColor;
  border-radius: 50%;
  background-color: transparent;

  &--prev {
    transform: rotate(180deg);
  }

  &:hover,
  &:focus {
    color: map-get($colors, blue-3);
  }

  @include respond-min(tablet) {
    width: 2.5rem;
    height: 2.5rem;

    &--prev {
      margin-left: auto;
      margin-right: 1.625rem;
    }
  }
}

.slider-arrows__button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.2rem;
  height: 2rem;
  transform: translate(-50%, -50%);

  @include respond-min(tablet) {
    width: 0.6rem;
    height: 1rem;
  }
}

.slider-arrows__pages {
  @include text('t-normal');
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 1rem;
  margin-right: 1rem;

  @include respond-min(tablet) {
    order: -1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.slider-arrows__page {
  width: 2.5rem;
  text-align: center;

  &--current {
    margin-right: 1.25rem;
  }

  &--total {
    margin-left: 1.25rem;
  }

  @include respond-min(tablet) {
    width: 1.25rem;

    &--current {
      margin-right: 0.625rem;
    }

    &--total {
      margin-left: 0.625rem;
    }
  }
}
