.tabs {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabs__header {
  display: flex;
  margin-bottom: 64px;
  align-items: center;
}

.tabs__title {
  font-size: 30px;

  position: relative;
  margin: 0 170px 0 0;
  align-self: flex-start;
}

.tabs__title::after {
  position: absolute;
  top: 52%;
  right: -125px;
  width: 87px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #5182dd;
  content: '';
}

.tabs__list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
}

.tabs__select {
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabs__select-btn {
  display: flex;
  align-items: center;
  padding: 1.75rem 2.5rem;
  text-align: left;
  color: rgba(map-get($colors, blue-1), 0.5);
  border: 1px solid map-get($colors, grey-3);
  background-color: transparent;

  .tabs__select:hover &,
  &:focus {
    color: map-get($colors, blue-1);
    border-color: map-get($colors, blue-1);
  }
}

.tabs__select-icon {
  width: 1.25rem;
  height: 0.625rem;
  margin-left: auto;

  .tabs__select-btn.opened & {
    transform: rotate(180deg);
  }
}

.tabs__items {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  @include respond-min(tablet) {
    position: static;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    opacity: 1;
    cursor: default;
  }
}

.tabs__item {
  @include respond-min(tablet) {
    @include proxima('sb');
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 2rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid transparent;

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      border-bottom: 2px solid map-get($colors, blue-3);
    }
  }
}

.tabs__link {
  @include respond-min(tablet) {
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
