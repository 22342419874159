.modal.fade-enter-active, .modal.fade-leave-active {
    transition: opacity .5s;
}
.modal.fade-enter, .modal.fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}

.js-modal{
}

.modal {
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
  }

  .modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: rgba(map-get($colors, black), 0.5);
    border: 0;
    opacity: 0;

    @include respond-min(tablet) {
      opacity: 1;
    }
  }

  .modal__content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .modal.fade-enter &, .modal.fade-leave-to & {
      transform: translateY(-30px);
    }
    .modal.fade-enter-to &, .modal.fade-leave & {
      transform: translateY(0px);
    }

    @include respond-min(tablet) {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: auto;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 26rem;
      max-height: 90vh;
    }
  }

  .modal__close-btn {
    z-index: 2;
    top: 2rem;
    width: 20px;
    position: absolute;
    right: 2rem;
    background-color: map-get($colors, white);

    &::before {
      z-index: -1;
      background-color: inherit;
    }

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    @include respond-min(tablet) {
      margin-right: -25px;
      margin-bottom: 5px;
      position: static;
      align-self: flex-end;
      color: map-get($colors, white);
      background-color: transparent;
      .icon-btn{
        color: white;
        &:hover{
            color: #608ad8;
        }
      }
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .modal__inner {
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .modal__wrapper {
    padding-top: 4rem;
    padding-bottom: 12.5rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @include respond-min(tablet) {
      padding-top: 2rem;
      padding-bottom: 2.5rem;
    }
  }

  .modal__title {
    position: relative;
    margin-bottom: 2.5rem;
    padding-right: 3.5rem;
    line-height: 1.5;

    @include respond-min(tablet) {
      margin-bottom: 1.25rem;
      padding-right: 1.75rem;
    }
  }

  .modal__form {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 1.5rem;
    }
  }

  .modal__input {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-min(tablet) {
      margin-bottom: 1.5rem;
    }
  }

  .modal__input--privacy-check {
    font-size: 10px;
    opacity: 0.7;

    margin-top: 20px;
  }

