.circle-slider__arrows{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0 24%;
    @include respond-max(desktop) {
        padding: 0 23%;
    }
    @include respond-max(tablet) {
        display: none;
    }
}

.circle-slider__arrow{
    width: 80px;
    height: 80px;
    border: 1px solid map-get($colors, blue-1);
    border-radius: 100%;
    cursor: pointer;
    z-index: 2;
    position: relative;
    opacity: .4;
    transition-duration: .5s;
    &:before{
        width: 10px;
        height: 10px;
        content: '';
        border-top: 1px solid map-get($colors, blue-1);
        border-left: 1px solid map-get($colors, blue-1);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
    &.prev{
        &:before{
            transform: rotate(-45deg) translate(1px, 1px);
        }
    }
    &.next{
        &:before{
            transform: rotate(135deg) translate(2px, 0px);
        }
    }
    &:hover{
        opacity: 1;
    }
}

.slider-pagination{
    position: static;
    margin-top: 25px;
    @include respond-max(tablet) {
        margin-top: 15px;
    }
    & > .swiper-pagination-bullet{
        margin: 0 8px;
        &-active{
            background-color: map-get($colors, blue-12);
        }
    }
}