.dropdown {
  position: relative;
  display: flex;
  align-self: stretch;
}



.dropdown__current {
  @include text('t-sm');
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.625rem 2rem;
  border: 1px solid map-get($colors, grey-3);
  background-color: transparent;
  word-break: break-word;

  &:hover,
  &:focus {
    color: map-get($colors, blue-3);
  }

  .dropdown--header & {
    padding: 0.625rem 1.875rem;
    border: 1px solid map-get($colors, blue-20);
    border-radius: 2rem;
  }

  @include respond-min(tablet) {
    @include text('t-sm');
    height: 100%;
    padding: 0.8125rem 1rem;

    .dropdown--header & {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
  }
}

.dropdown__current-icon {
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.5rem;
  margin-left: auto;
  will-change: transform;

  .dropdown.opened & {
    transform: rotate(180deg);
  }

  .dropdown--header & {
    margin-left: 0.5rem;
  }

  @include respond-min(tablet) {
    width: 0.375rem;
    height: 0.25rem;

    .dropdown--header & {
      margin-left: 0.25rem;
    }
  }
}

.dropdown__items {
  position: absolute;
  z-index: 2;
  top: calc(100% + 0.5rem);
  left: 0;
  display: flex;
  flex-direction: column;
  min-width: 22rem;
  opacity: 0;
  box-shadow: 0 2rem 2rem rgba(map-get($colors, blue-1), 0.16);
  transform: translateY(-20px);
  pointer-events: none;

  .dropdown.opened & {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }

  @include respond-min(tablet) {
    top: calc(100% + 0.25rem);
    min-width: 13.25rem;
    box-shadow: 0 1rem 1rem rgba(map-get($colors, blue-1), 0.16);
  }
}

.dropdown__item {
  display: flex;
  flex-direction: column;
}

.dropdown__item.is-disable {
  pointer-events: none;
  opacity: .6;
  button{
    background-color: #ececec;
  }
}

.dropdown__btn {
  @include text('t-sm');
  flex-shrink: 0;
  padding: 1.5rem 2rem;
  text-align: left;
  color: black!important;
  border: 0;
  white-space: nowrap;
  border-bottom: 1px solid rgba(map-get($colors, grey-3), 0.5);
  word-break: break-word;

  .dropdown__item:last-of-type & {
    border-bottom: 0;
  }

  &.active,
  &:hover,
  &:focus {
    background-color: rgba(map-get($colors, blue-18), 0.1);
  }

  @include respond-min(tablet) {
    padding: 0.75rem 1rem;
  }
}
