.radio {
  display: flex;
  justify-content: space-between;
}

.radio__element {
  display: flex;
  width: 48%;
}

.radio__label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.625rem;
  color: rgba(map-get($colors, blue-1), 0.5);
  text-align: center;
  word-break: break-word;
  cursor: pointer;

  &:hover,
  .radio__input:focus + & {
    color: map-get($colors, blue-1);
  }

  .radio__input:checked + & {
    color: map-get($colors, white);
    background-color: map-get($colors, blue-18);
  }

  .radio__input:checked:focus + &,
  .radio__input:checked + &:hover {
    opacity: 0.8;
  }

  @include respond-min(tablet) {
    padding: 0.8125rem;
  }
}
