.icon-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  font-size: 0;
  color: map-get($colors, blue-1);
  background-color: transparent;
  border: 0;

  &::before {
    content: '';
    position: absolute;
    top: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
    right: -0.25rem;
  }

  &:hover,
  &:focus {
    color: map-get($colors, blue-3);
  }
}

.icon-btn__icon {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
}

.icon-btn__badge {
  @include proxima('sb');
  position: absolute;
  top: -0.5rem;
  left: calc(100% - 0.75rem);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  text-align: center;

  .icon-btn:hover &,
  .icon-btn:focus & {
    background-color: map-get($colors, blue-3);
  }

  @include respond-min(tablet) {
    top: -0.25rem;
    left: calc(100% - 0.375rem);
    min-width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    font-size: 0.5rem;
  }
}
