.page {
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height);
  padding-bottom: var(--bottom-nav-height);

  @include respond-min(tablet) {
    padding-bottom: 0;
  }

  &--home{
    padding-top: 0;
  }

  &--stop-scroll {
    overflow-y: hidden;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .page {
    padding-top: 113px;
  }
}
