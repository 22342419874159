@mixin text($type: 'h4') {
  margin-top: 0;
  margin-bottom: 0;

  @if $type == 'h1' {
    @include proxima('sb');
    font-size: 2.8rem; // 36px
    line-height: 1.222; // 44px

    @include respond-min(tablet) {
      font-size: 2.25rem; // 36px
    }
  }

  @if $type == 'h2' {
    @include proxima('sb');
    font-size: 3rem; // 24px
    line-height: 1.208; // 29px

    @include respond-min(tablet) {
      font-size: 1.5rem; // 24px
    }
  }

  @if $type == 'h3' {
    @include proxima('sb');
    font-size: 2.25rem; // 18px
    line-height: 1.222; // 22px


    @include respond-min(tablet) {
      font-size: 1.125rem; // 18px
    }
  }

  @if $type == 't-xxxs' {
    font-size: 1.25rem; // 10px
    line-height: 1.5; // 15px

    @include respond-min(tablet) {
      font-size: 0.625rem; // 10px
    }
  }

  @if $type == 't-xxs' {
    font-size: 1.5rem; // 12px
    line-height: 1.5; // 18px

    @include respond-min(tablet) {
      font-size: 0.75rem; // 12px
    }
  }

  @if $type == 't-xs' {
    font-size: 1.625rem; // 13px
    line-height: 1.15; // 15px

    @include respond-min(tablet) {
      font-size: 0.8125rem; // 13px
    }
  }

  @if $type == 't-sm' {
    font-size: 1.75rem; // 14px
    line-height: 1.57; // 22px

    @include respond-min(tablet) {
      font-size: 0.875rem; // 14px
    }
  }

  @if $type == 't-normal' {
    font-size: 1.875rem; // 15px
    line-height: 1.667; // 25px

    @include respond-min(tablet) {
      font-size: 0.9375rem; // 15px
    }
  }

  @if $type == 't-md' {
    font-size: 2rem; // 16px
    line-height: 1.5; // 24px

    @include respond-min(tablet) {
      font-size: 1rem; // 16px
    }
  }
}
