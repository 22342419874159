
@font-face {
  font-family: 'Pacifico';
  src: url('../assets/fonts/pacifico/Pacifico-Regular.woff') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@mixin pacifico($weight: 'r') {
  @if $weight == 'r' {
    font-family: 'Pacifico', sans-serif;
    font-weight: 400;
  }
}
