*,
*::before,
*::after {
  box-sizing: border-box !important;
}

*:focus {
  outline: none !important;
}

html {
  @include fluid(
    'font-size',
    (
      375px: 8px,
      768px: 10px,
      1023px: 12px,
      1024px: 14px,
      1440px: 16px,
    )
  );
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  @include proxima;
  min-width: $mobile;
  min-height: 100vh;
  font-size: 2rem;
  line-height: 1.5;
  color: map-get($colors, black);

  @include respond-min(tablet) {
    font-size: 1rem;
  }
}

input {
  background-clip: padding-box;
  border-radius: 0;

  &[type='number'] {
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: currentColor !important;
    color: currentColor !important;
    box-shadow: inset 0 0 0 50% #ffffff !important;
  }
}

textarea {
  background-clip: padding-box;
  border-radius: 0;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;

  clip-path: inset(100%);
}

.no-scroll {
  overflow: hidden !important;
}

.only-mobile {
  display: flex !important;

  @include respond-min(tablet) {
    display: none !important;
  }
}

.only-desktop {
  display: none !important;

  @include respond-min(tablet) {
    display: flex !important;
  }
}

.wrapper-m {
  margin-left: $offset-mobile;
  margin-right: $offset-mobile;

  @include respond-min(tablet) {
    margin-left: $offset-desktop;
    margin-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    margin-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    margin-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.wrapper-p {
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet) {
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
    padding-right: calc(50vw - #{$desktop} / 2 + #{$offset-desktop});
  }
}

.wrapper-l {
  padding-left: $offset-mobile;
  padding-right: $offset-mobile;

  @include respond-min(tablet) {
    padding-left: $offset-desktop;
    padding-right: $offset-desktop;
  }

  @include respond-min(desktop) {
    padding-left: calc(50vw - #{$desktop-md} / 2 + #{$offset-desktop});
    padding-right: calc(50vw - #{$desktop-md} / 2 + #{$offset-desktop});
  }
}

.shadow {
  box-shadow: $shadow;
}

.content-page{
  max-width: 820px;
  margin: 5vh auto;
  p{
    color: $main-sub-color;
    font-weight: 300;
    margin-top: 20px;
    @include fluid('font-size', (500px: 16px, 1440px: 16px));
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
  .btn{
    padding: 8px 30px;
    width: auto;
    margin-top: 10px;
    text-decoration: none;
    max-width: 290px;
  }
}

.content-page__part, .content-page__list > li{
  @include fluid('margin-bottom', (500px: 35px, 1440px: 50px));
  &:last-child{
    margin-bottom: 0;
  }
}

.content-page__title{
  @include fluid('font-size', (500px: 24px, 1440px: 32px));
}

.content-page__list, .content-page__list ol{
  counter-reset: item;
  li{
    display: block;
    font-size: 21px;
    color: $main-text-color;
    font-weight: bold;
    ol{
      li{
        display: flex;
        color: $main-sub-color;
        font-weight: 300;
        margin-top: 20px;
        @include fluid('font-size', (500px: 16px, 1440px: 16px));
        &:before{
		  counter-increment: item;
          content: counter(item) ".";
          margin-right: 15px;
          min-width: 15px;
        }
      }
      p{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      ul{
        margin-left: 27px;
        li{
          &:before{
            content: '▪';
            margin-right: 10px;
			font-size: 12px;
          }
        }
      }
      
    }
  }
}

.content-page__contacts{
  display: block;

  a{
    color: $main-text-color;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 5px;
    svg{
      margin-left: 5px;
      width: 14px;
      height: 14px;
    }
  }
}

.container{
  padding: 0 3rem;
}
