@font-face {
  font-family: 'ProximaNova Light';
  src: url('../assets/fonts/proxima/ProximaNova-Light.woff2') format('woff2'),
    url('../assets/fonts/proxima/ProximaNova-Light.woff') format('woff');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova Regular';
  src: url('../assets/fonts/proxima/ProximaNova-Regular.woff2') format('woff2'),
    url('../assets/fonts/proxima/ProximaNova-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova Semibold';
  src: url('../assets/fonts/proxima/ProximaNova-Semibold.woff2') format('woff2'),
    url('../assets/fonts/proxima/ProximaNova-Semibold.woff') format('woff');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova Bold';
  src: url('../assets/fonts/proxima/ProximaNova-Bold.woff2') format('woff2'),
    url('../assets/fonts/proxima/ProximaNova-Bold.woff') format('woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@mixin proxima($weight: 'r') {
  @if $weight == 'l' {
    font-family: 'ProximaNova Light', sans-serif;
    font-weight: 300;
  }

  @if $weight == 'r' {
    font-family: 'ProximaNova Regular', sans-serif;
    font-weight: 400;
  }

  @if $weight == 'sb' {
    font-family: 'ProximaNova Semibold', sans-serif;
    font-weight: 600;
  }

  @if $weight == 'b' {
    font-family: 'ProximaNova Bold', sans-serif;
    font-weight: 700;
  }
}
