.range {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  @include respond-min(tablet) {
    padding-top: 1rem;
  }
}

.range__slider {
  position: relative;
  height: 1px;
  margin-bottom: 2.625rem;
  padding: 0 0.8125rem;
  border: 0;
  box-shadow: none;

  .noUi-base {
    &::before {
      content: '';
      position: absolute;
      top: -1rem;
      bottom: -1rem;
      left: 0;
      right: 0;
    }
  }

  .noUi-connect {
    background-color: map-get($colors, blue-5);
  }

  .noUi-handle {
    top: -0.8125rem;
    right: -0.8125rem;
    width: 1.625rem;
    height: 1.625rem;
    border: 0;
    border-radius: 50%;
    background-color: map-get($colors, blue-5);
    box-shadow: none;
    cursor: pointer;

    &::before,
    &::after {
      display: none;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1.3125rem;
    padding: 0 0.40625rem;

    .noUi-base {
      &::before {
        top: -0.5rem;
        bottom: -0.5rem;
      }
    }

    .noUi-handle {
      top: -0.40625rem;
      right: -0.40625rem;
      width: 0.8125rem;
      height: 0.8125rem;
    }
  }
}

.range__inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.range__input {
  width: 48%;
  padding: 1.625rem 1.5rem;
  border: 1px solid map-get($colors, grey-3);

  &::placeholder {
    color: inherit;
    opacity: 0.5;
    transition: $trs;
  }

  &:hover,
  &:focus {
    &::placeholder {
      opacity: 1;
    }
  }

  @include respond-min(tablet) {
    padding: 0.8125rem 0.75rem;
  }
}
