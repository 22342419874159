@each $key, $val in $colors {

  /* Background */
  .bg-#{$key} {
    background-color: $val;
  }

  /* Color */
  .clr-#{$key} {
    color: $val;
  }

}
