.product-page {
  display: flex;
  flex-direction: column;
}

.product-page__title {
  order: -1;
}

.product-page__discontinued {
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  order: -1;
  @include respond-min(tablet) {
    margin-bottom: 1.5rem;
    width: 950px;
    max-width: 100%;
    flex-direction: row;
    align-items: flex-start;
    column-gap: 140px;
  }
}

.product-page__discontinued-text {
  line-height: 140%;
  margin-bottom: 4rem;
  p:not(:first-child) {
    margin-top: 1rem;
  }
  @include respond-min(tablet) {
    margin-bottom: 0;
  }
}

.product-page__discontinued-btn {
  white-space: nowrap;
  width: 50%;
  border: none;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  @include respond-min(tablet) {
    width: 235px;
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
  }
}

.product-page__info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5rem;
  }

  .product-page__info-description,
  .product-page__info-controls {
    @include respond-min(tablet) {
      flex: 1;
      padding-left: 2.5rem;
    }
  }
}

.product-page__info-gallery {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  margin-left: -$offset-mobile;
  margin-right: -$offset-mobile;
  user-select: none;

  @include respond-min(tablet) {
    width: 25vw;

    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.product-page__info-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .swiper-container {
    width: 100%;
  }

  &--thumb {
    .swiper-container {
      width: calc(100% - 8rem);
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 100%;
      margin-top: 0;
      background-image: none;

      &::after {
        display: none;
      }
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev {
      left: 0;
      transform: rotate(180deg);
    }

    .swiper-button-icon {
      width: 2rem;
      height: 2rem;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;

    &--thumb {
      .swiper-container {
        width: calc(100% - 4rem);
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 2rem;
      }

      .swiper-button-icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.product-page__info-slider-discontinued {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(map-get($colors, black), .7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
  z-index: 3;
  span {
    font-size: 24px;
    line-height: 1.2;
  }
}

.product-page__info-slide {
  width: calc(100% / 3);
  cursor: pointer;
}

.product-page__info-img {
  width: 100%;
  height: 51rem;
  max-height: 40vh;
  padding: 2rem;

  img {
    width: auto;
    height: auto;
    margin: auto;
  }

  @include respond-min(tablet) {
    height: 25vw;
    max-height: none;
    padding: 1rem;
  }
}

.product-page__info-thumb {
  width: 100%;
  height: 10rem;
  padding: 1rem;
  border: 1px solid map-get($colors, white);

  .swiper-slide-active & {
    border: 1px solid map-get($colors, blue-3);
  }

  @include respond-min(tablet) {
    height: 5rem;
    padding: 0.5rem;
  }
}

.product-page__info-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  & > * {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 0;
  }
}

.product-page__info-title {
  margin-bottom: 0;

  a {
    &:hover {
      color: map-get($colors, blue-7);
    }
  }
}

.product-page__info-controls {
  display: flex;
  flex-direction: column;
}

.product-page__info-price {
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;
  }
}

.product-page__info-table {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  thead,
  tbody {
    display: flex;
    flex-direction: column;
  }

  tbody {
    border-bottom: 1px solid rgba(map-get($colors, black), 0.1);
  }

  tr {
    display: flex;
    align-items: center;

    &.active {
      background-color: map-get($colors, blue-14);
    }
  }

  th,
  td {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 1.25rem;
    border-top: 1px solid rgba(map-get($colors, black), 0.1);
    border-left: 1px solid rgba(map-get($colors, black), 0.1);

    &:nth-of-type(2n) {
      border-right: 1px solid rgba(map-get($colors, black), 0.1);
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;

    th,
    td {
      padding: 0.625rem;
    }
  }
}

.product-page__info-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem 3rem;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
    flex-direction: column;

    & > * {
      width: 100%;
    }

    @include respond-min(mobile-lg) {
      flex-direction: row;
      & > * {
        width: unset;
      }
    }
  }

  @include respond-min(tablet) {
    gap: 2rem 1.5rem;
    margin-bottom: 1rem;
  }
}

.product-page__info-amount {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1.5px solid map-get($colors, blue-10);
  border-radius: 3rem;

  button {
    position: relative;
    width: 3rem;
    height: 3rem;
    padding: 0;
    font-size: 0;
    color: inherit;
    border: 0;
    background-color: transparent;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 1px;
      background-color: currentColor;
      transform: translate(-50%, -50%);
    }

    &:nth-of-type(2) {
      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  input {
    border: 0;
    text-align: center;
    color: inherit;
  }

  @include respond-min(tablet) {
    padding: 0.5rem;
    border-radius: 1.5rem;

    button {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.product-page__info-btn {
  @include text('t-xxs');
  flex-grow: 1;
  width: auto;
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;

  @include respond-min(tablet) {
    padding-top: 0.6875rem;
    padding-bottom: 0.6875rem;
  }
}

.product-page__info-description{
  grid-gap: 25px;
}

.product-page__models {
  overflow-x: auto;
  margin-top: -78px;
  margin-bottom: 30px;
}

.product-page__tabs {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 5rem;
  }

  &--order {
    order: -1;
    margin-top: 0 !important;
    .product-page__similar-slider {
      margin-bottom: 4rem;
    }
  }
}

.product-page__tabs-header {
  margin-bottom: 3rem;

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;
  }
}

.product-page__tabs-content {
  display: flex;
  align-items: flex-start;
}

.product-page__tabs-main {
  max-width: 920px;
}

.product-page__tabs-block {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 0;
  height: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.75s;

  &.active {
    overflow: visible;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.product-page__description {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1600px) {
    font-size: 18px;
  }

  & > * {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  img {
    align-self: flex-start;
    width: 100%;
    height: auto;
  }

  ul {
    li {
      position: relative;
      padding-left: 3.25rem;

      &::before {
        content: '';
        position: absolute;
        top: 1.25rem;
        left: 0;
        width: 1.5rem;
        height: 1px;
        background-color: currentColor;
      }
    }
  }

  table {
    display: block;
    width: fit-content;
    max-width: calc(100vw - #{$offset-mobile} * 2);
    overflow-x: auto;

    @include respond-min(tablet) {
      max-width: calc(100vw - #{$offset-desktop} * 2);
    }
  
    @include respond-min(desktop) {
      max-width: calc(100vw - (50vw - #{$desktop} / 2 + #{$offset-desktop}) * 2);
    }
  }

  th,
  td {
    @include text('t-md');
    padding: 1.25rem;
    border: 1px solid rgba(map-get($colors, black), 0.1);
    vertical-align: middle;
  }

  i {
    font-style: italic;
  }

  @include respond-min(tablet) {
    & > * {
      margin-bottom: 1.25rem;
    }

    ul {
      li {
        padding-left: 1.625rem;

        &::before {
          top: 0.625rem;
          width: 0.75rem;
        }
      }
    }

    th,
    td {
      padding: 0.625rem;
    }
  }
}

.product-page__charachteristics {
  display: flex;
  flex-direction: column;
}

.product-page__charachteristics-row {
  display: flex;
  flex-direction: column;
  padding: 2.5rem;

  &:nth-of-type(odd) {
    background-color: map-get($colors, blue-14);
  }

  dt {
    color: map-get($colors, blue-22);
  }

  dd {
    color: map-get($colors, blue-23);
  }

  @include respond-min(tablet) {
    flex-direction: row;
    padding: 1.25rem;

    dt,
    dd {
      width: 50%;
      padding-right: 1rem;
    }
  }
}

.product-page__documents {
  display: flex;
  flex-direction: column;
}

.product-page__documents-file {
  @include proxima('sb');
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-bottom: 1.25rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    color: map-get($colors, blue-1);
  }

  span {
    &:first-of-type {
      flex-grow: 1;
      margin-right: 1.25rem;
    }
  }

  @include respond-min(tablet) {
    margin-bottom: 0.625rem;

    span {
      &:first-of-type {
        margin-right: 0.625rem;
      }
    }
  }
}

.product-page__documents-icon {
  flex-shrink: 0;
  width: 1.875rem;
  height: 2.375rem;
  margin-left: 1.25rem;

  @include respond-min(tablet) {
    width: 0.9375rem;
    height: 1.1875rem;
    margin-left: 0.625rem;
  }
}

.product-page__video {
  max-width: 100%;
  height: calc((100vw - #{$offset-mobile} * 2) * 9 / 16);
  max-height: 60vh;
  align-self: center;
}

.product-page__industry-solutions {
  display: grid;
  grid-gap: 3.5rem;
  grid-template-columns: 1fr;
  width: 100%;

  @include respond-min(tablet) {
    grid-gap: 1.75rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.product-page__similar-products {
  display: flex;
  flex-direction: column;
}

.product-page__similar-header {
  position: relative;
  margin-bottom: 3rem;
  padding-left: 7rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 5rem;
    height: 1px;
    background-color: map-get($colors, blue-10);
    transform: translateY(-50%);
  }

  @include respond-min(tablet) {
    margin-bottom: 1.5rem;
    padding-left: 3.5rem;

    &::before {
      width: 2.5rem;
    }
  }
}

.product-page__similar-slider {
  width: 100%;
}

.product-page__similar-slide {
  height: auto;

  @include respond-min(tablet-sm) {
    width: calc((100% - 1.75rem) / 2);
    margin-right: 1.75rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @include respond-min(tablet) {
    width: calc((100% - 1.75rem * 2) / 3);
  }

  @include respond-min(desktop-sm) {
    width: calc((100% - 1.75rem * 3) / 4);
  }
}
