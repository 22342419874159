.link-arrow {
  position: relative;
  padding-right: 2rem;

  @include respond-min(tablet) {
    padding-right: 1rem;
  }
}

.link-arrow__icon {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1rem;
  height: 1.25rem;
  transform: translateY(-50%);

  .link-arrow:hover &,
  .link-arrow:focus & {
    transform: translate(1rem, -50%);
  }

  @include respond-min(tablet) {
    width: 0.5rem;
    height: 0.625rem;

    .link-arrow:hover &,
    .link-arrow:focus & {
      transform: translate(0.5rem, -50%);
    }
  }
}
