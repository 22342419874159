/* Custom variables */

:root {
  --scrollbar-width: 17px;
}


/* Viewports */

$mobile: 320px;
$mobile-md: 360px;
$mobile-lg: 640px;
$tablet-sm: 768px;
$tablet: 1024px;
$desktop-sm: 1280px;
$desktop: 1440px;
$desktop-md: 1520px;
$desktop-lg: 1600px;
$desktop-xlg: 1920px;


/* Colors */

$colors: (
  black: #000000,
  white: #ffffff,
  blue-1: #1f3176,
  blue-2: #2b3b7d,
  blue-3: #608ad8,
  blue-4: #6c7c9f,
  blue-5: #7c9bd8,
  blue-6: #759add,
  blue-7: #1d2e7a,
  blue-8: #3e81b4,
  blue-9: #42c6ef,
  blue-10: #5182dd,
  blue-11: #304a94,
  blue-12: #3a478f,
  blue-13: #293981,
  blue-14: #eff3f9,
  blue-15: #a7b6d5,
  blue-16: #c1d1ee,
  blue-17: #212d62,
  blue-18: #5a84d7,
  blue-19: #3c498b,
  blue-20: #8a92b9,
  blue-21: #3c4a8c,
  blue-22: #798bb1,
  blue-23: #263970,
  blue-24: #628AD8,
  blue-25: #203275,
  blue-26: #223372,
  blue-27: #00314f,
  grey-1: #f1f1f1,
  grey-2: #f7f9fc,
  grey-3: #cad0db,
  grey-4: #9199a8,
  grey-5: #c9d0dc,
  grey-6: #d3d8e2,
  grey-7: #f0f3f9,
  grey-8: #b9c4d3,
  grey-9: #98a3b4,
  grey-10: #d4dae5,
  grey-11: #333333,
  grey-12: #F0F3F8,
  grey-13: #E6ECF7,
  grey-14: #6F7480,
  grey-15: #e6ecf1,
  red-1: #e6283e,
  red-2: #ee7891,
  red-3: #D4493E,
  red-4: #D65B71,
  orange-1: #eb6445,
  green-1: #5ca962,
  purple-1: #9779b3,
  purple-2: #D2DDF1,
  yellow-1: #e2cd3d,
  pink-1: #a96fa4,
);


$main-text-color: #1F3176;
$main-sub-color: #626F9F;
$grey-light: #9399A7;

/* Offsets */

$offset-mobile: 2rem;
$offset-desktop: 2rem;


/* Transition */

$trs: all 0.4s cubic-bezier(0.33, 0, 0, 1);


/* Box shadow */

$shadow: 0 5px 38px rgba(34, 49, 100, 0.27);
