/* Headings */

.t-h1 {
  @include text('h1');
}

.t-h2 {
  @include text('h2');
}

.t-h3 {
  @include text('h3');
}


/* Text */

.t-xxxs {
  @include text('t-xxxs');
}

.t-xxs {
  @include text('t-xxs');
}

.t-xs {
  @include text('t-xs');
}

.t-sm {
  @include text('t-sm');
}

.t-normal {
  @include text('t-normal');
}

.t-md {
  @include text('t-md');
}


/* Style */

.t-ttu {
  text-transform: uppercase;
}

.t-center {
  text-align: center;
}

.t-tdn {
  text-decoration: none;
}
